@import '/src/scss/variables.scss';

.onboarding-checklist {
  background: #f5f5f5;
  padding: 24px;
  border-radius: 8px;

  &__title {
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  }
}

.step {
  background-color: $white;
  border-radius: 4px;
  border: 1px solid rgba(38, 40, 66, 0.08);
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  width: 100%;

  &.disabled {
    background: none;
    color: #adadad;
  }

  &__title {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
  }

  &__icon {
    svg {
      path:nth-child(7) {
        fill: #40b84c;
      }
    }
  }

  .status {
    svg {
      path {
        fill: #137547;
      }
    }
  }

  .start {
    height: 24px;
    border-radius: 4px;
  }
}

.link {
  color: #40b84c;
  text-decoration: none;

  &:hover {
    color: #40b84c;
  }
}

.externalIcon {
  svg {
    path {
      stroke: #ffffff;
    }
  }
}

@import '/src/scss/variables.scss';

.team-tab {
  &-table {
    tbody {
      tr {
        &:first-child {
          td {
            &:last-child {
              button {
                &:last-child {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

.compliance-tab {
  background: #f5f5f5;
  padding: 16px;
  border-radius: 8px;
  width: fit-content;

  &__content {
    background-color: $white;
    border-radius: 4px;
    border: 1px solid rgba(38, 40, 66, 0.08);
    padding: 16px;
    display: flex;
    align-items: start;
    gap: 12px;
  }
  .verify-button {
    margin-left: 110px;
  }

  &__status {
    padding: 4px 8px;
    border-radius: 4px;
    display: flex;
    gap: 4px;

    &__start {
      background-color: #ebebeb;
      color: #6d6d6c;
      svg {
        path {
          fill: #6d6d6c;
        }
      }
    }
    &__approved {
      background-color: #dbfcee;
      color: #2db77b;
      svg {
        path {
          fill: #2db77b;
        }
      }
    }
    &__rejected {
      background-color: #ffe2de;
      color: #d6331f;
      svg {
        path {
          fill: #d6331f;
        }
      }
    }
    &__retry {
      background: #f0f9ff;
      color: #026aa2;
      svg {
        path {
          fill: #026aa2;
        }
      }
    }
    &__submitted {
      background-color: #ffe8c2;
      color: #ffa530;
      svg {
        path {
          fill: #ffa530;
        }
      }
    }
  }
}

@import './../../scss/variables.scss';

$main-color: #878787;
$sidebar-width: 240px;
$background: #f5f5f5;

@mixin section-item {
  display: flex;
  gap: 8px;
  padding: 8px 12px;
  width: 100%;

  @media screen and (max-height: 600px) {
    padding: 6px 12px;
  }
}

@mixin hover {
  border-radius: 4px;
  color: $primary_light;
}

.root {
  position: relative;
  min-width: $sidebar-width;
  max-width: $sidebar-width;
  background: $background;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: 'Inter';
  font-style: normal;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  z-index: 1;
}

.links {
  padding: 0px 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 16px;
  overflow: auto;
}

.logo {
  padding: 24px 12px;
}

.section {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__title {
    @include section-item();
    padding: 4px 12px;
    font-size: 12px;
    color: #6d7175;
  }

  &__item {
    @include section-item();
    text-decoration: none;
    color: $main-color;

    svg path {
      fill: $main-color;
    }

    &__external {
      svg path {
        fill: none;
      }
    }

    &-badge {
      padding: 2px 8px;
      border-radius: 100px;
      height: 20px;
      min-width: 20px;
      line-height: 1;
      background: #e4e5e7;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      color: #484848;
    }

    &-counter {
      background-color: #ff4545;
      color: white;
      text-align: center;
      border-radius: 8px;
      padding: 0px 8px;
      margin-left: auto;
      font-family: Inter;
      font-size: 10px;
      font-weight: 600;
      line-height: 14px;
    }

    &_active {
      @include hover();
      background: #ffffff;
      font-weight: 600;
      color: $primary_light !important;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05),
        inset 2.5px 0px 0px #137547;

      svg path {
        fill: $primary_light;
      }
    }

    &:hover {
      @include hover();

      svg path {
        fill: $primary_light;
      }

      .section__item__external {
        svg path {
          fill: none;
          stroke: $primary_light;
        }
      }
    }
  }
}

.footer-section {
  flex-grow: 1;
  justify-content: flex-end;

  &-warning {
    background: #f9fafb;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.06);
    border-radius: 8px;
    padding: 24px 16px;

    &__icon {
      color: $red;
      margin-bottom: 14px;
    }

    &__title {
      color: $red;
      margin-bottom: 4px;
    }

    &__description {
      color: $black_light;
    }
  }
}

.profile-section {
  padding: 24px 8px;
  width: 100%;
  color: $main-color;

  @media screen and (max-height: 600px) {
    background: $background;
    border-top: 1px solid #dfdfdf;
    position: sticky;
    bottom: 0;
    padding: 12px 8px;
  }

  &__item {
    @include section-item();

    svg path {
      fill: $main-color;
    }

    &-label {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: calc($sidebar-width - 100px);
    }

    &_active {
      color: $primary;
      font-weight: 600;

      svg path {
        fill: $primary;
      }
    }
  }
}

.sidebar-menu {
  z-index: 1;
  position: absolute;
  padding: 4px 0px;
  left: calc(50% - 214px / 2);
  bottom: 65px;
  background: #ffffff;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  width: 350px;
}

.sidebar-menu:after,
.sidebar-menu:before {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.sidebar-menu:after {
  border-color: transparent;
  border-top-color: #fff;
  border-width: 12px;
  margin-left: -60px;
}

.sidebar-menu__item {
  padding: 8px 14px;

  &:hover {
    background: #f5f5f5;
  }
}

.sidebar-menu__account_item {
  padding: 8px 14px;
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  align-items: center;

  &:hover {
    background: #f5f5f5;
  }
}

.sidebar-menu__account_item_active {
  padding: 8px 14px;
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  align-items: center;
  background-color: #f6f6f6;
}

.sidebar-menu__account_item_content {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.sidebar-menu__account_item_content_text {
  align-items: flex-start;
  row-gap: 4px;
  display: flex;
  flex-direction: column;
}

.sidebar-menu__account_item_content_text_primary {
  color: black;
}

.sidebar-menu__account_item_content_buttons {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  justify-content: start;
}

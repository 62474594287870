.styledCheckbox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background: transparent;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 4px;
  transition: all 150ms;
}

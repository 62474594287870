@import 'scss/base.scss';
@import 'scss/colors.scss';

a:hover {
  color: #121212;
}

.remo-form-label {
  margin-bottom: 4px;
}

.remo-form-input {
  width: 100%;
  margin-bottom: 16px;
}

.select {
  .react-select {
    &__control {
      border-radius: 8px;
      font-size: 14px;
      line-height: 20px;
    }

    &__indicator-separator {
      display: none;
    }

    &__indicator {
      color: #878787;
    }
  }
}

.modal-content {
  padding: 16px;
  border-radius: 16px !important;
}
.modal-header {
  border-bottom: none;
  align-items: start;
  margin-bottom: 16px;
}
.modal-footer {
  border-top: none;
}
.alert {
  background: #f5f5f5;
  border-radius: 8px;
  padding: 12px 16px;
  border: none;
  font-size: 13px;
}

.alert-info {
  background-color: #ebf5fc;
  color: #4c92c7;
  display: flex;
  gap: 4px;
  padding: 8px;

  svg path {
    fill: #4c92c7;
  }
}

.alert-warning {
  background-color: #ffe8c2;
  color: #ffa530;
  display: flex;
  gap: 4px;
  padding: 8px;

  svg path {
    fill: #ffa530;
  }
}

.progress {
  flex: 1;
  height: 100%;
  background: #fff;

  .progress-bar {
    background: #f5f5f5;
    color: #121212;
  }
}
.card {
  border-radius: 8px;
}
.bg-primary {
  background-color: #40b84c !important;
}
.stack-modal {
  z-index: 1055;
}

#chat_threads {
  flex: 1 1 10px;
  overflow: auto;
}

.card {
  border-radius: 8px;
  border-color: rgb(142 142 142 / 13%);

  .card-header {
    border-bottom: none;
    .card-title {
      font-family: 'Manrope', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 115%;
      margin-bottom: unset;
    }
  }
}

.tooltip-large .tooltip-inner {
  max-width: 400px;
  text-align: left;
}

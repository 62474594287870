.status {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 6px;
  border-radius: 4px;
  width: fit-content;
}

.status_default {
  border: 1px solid #dfdfdf;
  box-sizing: border-box;
}

.status_success {
  background: #e6f2e7;
  color: #137547;
}

.status_disabled {
  cursor: not-allowed;
  background: #f5f5f5;
}

.status_error {
  color: #ff4545;
  background: #ffefef;
  opacity: 0.9;
}

.status_warning {
  color: #da8f1f;
  opacity: 0.9;
  background: #fff6e6;
}

.status_active {
  background: #f0f9ff;
  color: #026aa2;
}

.status_warning_ap {
  padding: 4px 12px;
  border-radius: 16px;
  background: #ffa530;
  color: #ffffff;
}

.status_success_ap {
  padding: 4px 12px;
  border-radius: 16px;
  background: #497cf6;
  color: #ffffff;
}

.status_error_ap {
  padding: 4px 12px;
  border-radius: 16px;
  background: #d6331f;
  color: #ffffff;
}

@mixin border($line) {
  border-#{$line}: solid 1px #dfdfdf;
}

.aside {
  min-width: 578px;
  background: linear-gradient(0deg, #faf9f6, #faf9f6);
}

.title {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 36px;
  letter-spacing: -1px;
  width: 380px;
  margin-top: 100px;

  span {
    color: #40b84c;
  }
}

.link {
  color: #137547;
  text-decoration: none;

  &:hover {
    color: #137547;
  }
}

.logout {
  @include border(top);
  padding: 27px 33px;
  color: #878787;
}

.description {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 162.5%;
  color: #878787;
  margin-top: 15px;
}

.terms {
  display: flex;
  flex-direction: column;
  width: 655px;
}

.header {
  @include border(top);
  @include border(left);
  @include border(right);
  border-radius: 8px 8px 0px 0px;
  padding: 24px 32px;
}

.body {
  border: solid 1px #dfdfdf;
  padding: 0 60px;
  height: 550px;
  overflow: auto;
}

.footer {
  @include border(bottom);
  @include border(left);
  @include border(right);
  border-radius: 0px 0px 8px 8px;
  padding: 24px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    color: #137547;
    text-decoration: none;
  }
}

.documents {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  width: 552px;
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 24px;
}

.emptyDocuments {
  padding: 65px 70px;
}

.document_items {
  max-height: 500px;
}

.success {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 16px;
  width: 374px;
  text-align: center;
}

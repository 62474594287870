.arrow-wrap {
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    opacity: 0.67;
  }
  &__text-right {
    margin-right: 3px;
    font-size: 14px;
    font-weight: 500;
  }
  &__text-left {
    margin-left: 3px;
    font-size: 14px;
    font-weight: 500;
  }

  &--disabled {
    opacity: 0.48;
    cursor: not-allowed;
  }
}

@mixin base {
  cursor: pointer;
  padding: 2px;
  height: 44px;
  border: 1px solid #f3f3f3;
  border-radius: 8px;
  display: flex;
  align-items: center;
}

.dropzone {
  @include base;
}

.delete {
  @include base;
  justify-content: space-between;
}

.fileName {
  padding: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.btn {
  height: 40px;
  padding: 4px 16px;
  background: #f9f9f9;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.label {
  background-color: #f9f9f9;
  border-radius: 8px;
  border: 1px solid #fff;
  color: #121212;
  font-size: 13px;
  font-weight: 500;
  padding: 0.5rem;
  cursor: pointer;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: 1px solid #f3f3f3;
}

.attachIcon {
  margin-right: 0.5rem;
  transform: rotate(45deg);
}

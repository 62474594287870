@import '/src/scss/variables.scss';

.pages {
  display: flex;
  height: 100%;
}

.page-content-wrapper {
  width: 100%;
  overflow: auto;
  height: 100%;
}

.page-main {
  width: 100%;
  min-width: 1200px;
  min-height: 100vh;
}
.page-content {
  padding: 20px 20px 78px;
}

@media screen and (max-width: $medium_breakpoint_width) {
  .page-main {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
}

.acc-deactivate {
  background: #eff3f8;
  height: 55px;
  padding: 16px 80px;
  cursor: pointer;
}

@media screen and (max-width: $medium_breakpoint_width) {
  .acc-deactivate {
    padding: 16px 40px;
  }
}

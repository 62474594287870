@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Manrope:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&display=swap');
@import './variables.scss';
body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
button,
input {
  font-family: 'Inter', sans-serif;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.link {
  text-decoration: none;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  &__primary {
    color: $primary;
  }
  &__danger {
    color: $red;
  }
}

.rotate {
  transform: rotate(180deg);
}

.notification-block {
  background: #f5f5f5;
  border-radius: 8px;
  margin: 8px 0;
  padding: 12px 16px;
}

.notification-block_error {
  background: rgba(239, 67, 67, 0.12);
  border-radius: 8px;
  margin: 8px 0;
  padding: 12px 16px;
}

.notification-block_blue {
  background: #ebf5fc;
  border-radius: 8px;
  color: #4c92c7;
  margin: 8px 0;
  padding: 12px 16px;
}

.notification-card {
  background: #ffffff;
  width: 49%;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px 8px;
}

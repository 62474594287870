.status_multi_currency {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: fit-content;
  font-weight: 600;
  padding: 4px 12px;
  border-radius: 16px;
  border: 1px solid;
  font-size: 12px;
  line-height: 16px;
}

.status_to_approve_multi_currency {
  background: #ecf1fe;
  color: #497CF6;
}

.status_approved_multi_currency {
  background: #dbfcee;
  color: #2db77b;
}

.status_changes_requested_multi_currency {
  background: #fee7e5;
  color: #ffffff;
}

.status_to_approve_multi_currency_main {
  background: #497cf6;
  color: #ffffff;
}

.status_partially_approved_multi_currency_main {
  background: #ffa530;
  color: #ffffff;
}

.status_approved_multi_currency_main {
  background: #2db77b;
  color: #ffffff;
}

.select {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  label {
    position: relative;
    top: 0;
    left: 0;
    color: #121212;
    display: block;
  }

  .select-wrapper {
    position: relative;
    width: 100%;
  }

  .select-start-icon {
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    z-index: 1;
  }

  .react-select__control {
    min-height: 40px !important;
    border: 1px solid #dfdfdf !important;
    border-radius: 8px !important;
    box-shadow: none !important;
  }

  .react-select__value-container {
    padding-left: 0 !important;
  }

  .react-select__input {
    margin-left: 0 !important;
    padding-left: 0 !important;
  }

  .react-select__menu {
    z-index: 2;
  }
}

.react-select__option {
  background-color: white !important;
}

.react-select__option--is-focused {
  background-color: rgba(0, 0, 0, 0.02) !important;
}

.react-select__option--is-selected {
  color: #121212 !important;
  background-color: #f3f3f3 !important;
}

.react-select__control {
  min-height: 40px !important;
  border: 1px solid #dfdfdf !important;
}

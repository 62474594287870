.tab {
  cursor: pointer;
  opacity: 1;
  position: relative;
  background: none;
  border: none;
  margin: 0;
  outline: none;
  display: flex;
  align-items: center;
  padding: 0 12px 7px 12px;
  transition: all 0.1s;
  white-space: nowrap;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  border-bottom: 1px solid #dfdfdf;

  &:first-child {
    padding: 0 12px 7px 0;
  }
}
.tabs__line {
  display: flex;
  width: 100%;
  height: 29px;
  border-bottom: 1px solid #dfdfdf;
}

.tab_active {
  color: #121212;
  border-bottom: 1px solid #40b84c;
}
.tab_disabled {
  color: #878787;
}

.tabs {
  height: max-content;
  ::-webkit-scrollbar {
    display: none;
  }
}

.tab-count {
  width: 18px;
  height: 18px;
  background: #40b84c;
  border-radius: 50px;
  display: flex;
  margin-left: 8px;
  align-items: center;
  justify-content: center;
}

.tab-count_text {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 115%;
  text-align: center;
  color: white;
  letter-spacing: 0.03em;
  text-transform: uppercase;
}

@import '/src/scss/variables.scss';

.overview-page {
  &__title {
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 24px;
  }
  &__cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  &__chart {
    height: 300px;
    margin-bottom: 2rem;
    position: relative;
  }
}

.custom-tooltip {
  background-color: #101828;
  border: 0;
  border-radius: 4px;
  color: #fff;
  padding: 6px 8px;

  p {
    font-size: 13px;
    font-weight: 500;
  }
}

.overview-page__chart-info {
  margin-bottom: 16px;
  width: fit-content;
}

.overview-page-stats {
  background: #ffffff;
  border: 1px solid #eaecf0;
  border-radius: 8px;
  padding: 24px;
}

.chart-info {
  background-color: #fff;
  border: 1px solid #f3f3f3;
  border-radius: 8px;
  padding: 1.5rem;
  min-width: 245px;

  &__title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 12px;
  }
  &__amount {
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 16px;
  }
  &__description {
    color: #878787;
    font-size: 14px;
    font-weight: 500;

    &--gray {
      color: #878787;
    }

    &--success {
      color: #027a48;

      svg {
        path {
          stroke: #027a48;
        }
      }
    }
    &--danger {
      color: red;

      svg {
        transform: rotate(180deg);
        path {
          stroke: red;
        }
      }
    }
    span {
      margin-right: 8px;
    }
  }
}

.recharts-cartesian-axis-tick * {
  font-family: 'Inter';
}

.recharts-cartesian-axis line,
.recharts-cartesian-grid line {
  stroke: #f2f4f7;
}

.recharts-xAxis .recharts-cartesian-axis-tick:first-child {
  transform: translateX(20px);
}

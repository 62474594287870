.customers-page {
  &-header {
    font-family: Manrope;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 115%;
    margin-top: 24px;
  }
  &-search {
    width: 248px;
  }
}

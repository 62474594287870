@import '/src/scss/variables.scss';

.customer-payroll {
  height: 100%;
  padding-bottom: 140px;
  &-header {
    display: flex;
    flex-direction: row;
  }
  &-divider {
    width: 100%;
    margin-top: 30px;
    height: 1px;
    background: #efecef;
  }
  &-table-box {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 24px;
  }
  &-header-row {
    display: flex;
    flex-direction: row;
    background: #f8f7f8;
    height: 72px;
    align-items: center;
    justify-content: space-between;
    padding-left: 24px;
    padding-right: 24px;
    border-bottom: 1px solid #e1e1e1;
  }
  &-content-row {
    display: flex;
    flex-direction: row;
    background: white;
    height: 72px;
    align-items: center;
    justify-content: space-between;
    padding-left: 24px;
    padding-right: 24px;
  }

  &-table-wrap {
    svg {
      color: #121212;
      &:hover {
        color: #40b84c;
      }
    }
  }

  &-table {
    overflow-y: auto;
    overflow-x: auto;
  }

  @media screen and (max-width: $medium_breakpoint_width) {
    &-table {
      overflow-y: auto;
      overflow-x: auto;
    }
  }
}

.payroll-bottom-wrapper {
  border-top: 1px solid #efecef;
  box-shadow: 0px -1px 0px #efecef;
  position: fixed;
  bottom: 0;
  right: 0;
  background: white;
  width: calc(100% - 240px);
  display: flex;
  @media screen and (max-width: $medium_breakpoint_width) {
    width: calc(100% - 68px);
  }
}

.payroll-bottom {
  padding: 16px 80px;
  width: 100%;
}

.payrolls-table {
  border-width: 1px;
  border-style: solid;
  border-color: #f3f3f3;
  border-radius: 8px 8px 0px 0px;
}

.payrolls-table-header {
  background: #f3f3f3;
  padding: 24px;
}

@import '/src/scss/variables.scss';

.number-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 510px;
  height: 210px;
  padding: 24px;
  @media screen and (max-width: $medium_breakpoint_width) {
    min-width: auto;
  }
}
.number-card_link {
  background: #ffffff;
  border: 1px solid #dfdfdf;
  box-sizing: border-box;
  border-radius: 8px;
  display: block;
  min-width: 0;

  &:hover {
    background: #fafafa;
  }
  &:active {
    background: #f9f9f9;
  }
  width: 100%;
}
.number {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 100%;
  color: #40b84c;
}

.number-card__text-content {
  max-width: 200px;
  min-width: 200px;
  display: flex;
  flex-direction: column;
}

.title {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
}

.subtitle {
  margin-top: 4px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #878787;
}

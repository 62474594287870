.invoice-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  &-bg {
    background: rgba(0, 0, 0, 0.2); /* Black w/ opacity */
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
  &-content-wrap {
    width: 440px;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
    z-index: 1;
  }

  &-header {
    margin-left: 40px;
    margin-top: 40px;
    margin-bottom: 24px;
  }

  &-title {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 115%;
    color: #070707;
  }

  &-subtitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #878787;
  }

  &-divider {
    width: 100%;
    height: 1px;
    background: #efecef;
  }

  &-info-section {
    padding-left: 40px;
    padding-right: 40px;
    margin-top: 6px;
  }

  &-info-row {
    margin-top: 18px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &-row-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #878787;
  }

  &-row-subtitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #121212;
    max-width: 250px;
  }

  &-buttons-section {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 40px;
    padding-right: 40px;
    margin-bottom: 40px;
  }
}

.download-button {
  width: 100%;
  height: 48px;
  background: #000000;
  border-radius: 8px;
  color: white;
}

.close-button {
  width: 100%;
  height: 48px;
  margin-top: 12px;
  background: white;
  border-radius: 8px;
  border: 1px solid #cccccc;
  cursor: pointer;
}
